import React, { useState, useCallback, useRef, useEffect } from "react"
import { graphql, Link } from "gatsby"

import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"

import bgTrans from '../assets/images/eshop-main.svg'
import shadowLines from '../assets/images/shadow_lines.png'
import perfectCode from '../assets/images/Bounce Rate.svg'
import platformChoise from '../assets/images/Search.svg'
import uploadCircle from '../assets/images/Sales.svg'
import modelA from '../assets/images/PWA.svg'
import modelB from '../assets/images/Adaptability.svg'
import modelC from '../assets/images/Security.svg'
import modelD from '../assets/images/Code Splitting.svg'
import modelE from '../assets/images/Lazy Loading.svg'
import modelF from '../assets/images/CDN.svg'
import modelG from '../assets/images/Auto Scaling.svg'
import modelH from '../assets/images/Prerendering.svg'
import modelI from '../assets/images/GPU.svg'

import eTrans from '../assets/images/eshop-trans.svg'
import eshopIn from '../assets/images/eshop-in.webp'
import eshopIn400 from '../assets/images/eshop-in-400.webp'
import logo from '../assets/images/logo.webp'
import loadable from "@loadable/component"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Faqs from '../components/ServiceDetails/Faqs'


// import ReactPlayer from 'react-player'
// import ContactPromo from "../components/Promo/ContactPromo"

import { StaticImage } from "gatsby-plugin-image"

const ContactPromo = loadable(
    () => import("../components/Promo/ContactPromo")
)

const VideoPromo = loadable(
    () => import("../components/Promo/Video")
)



const EshopPromotionalTemaplate = (props) => {
    const ref = useRef()
    const [inView, setInView] = useState(false)

    let data = props.data.wpgraphql;

    console.log(data.page.promoAcf.faqs);

    const handleScroll = useCallback((data) => {
        if (window.pageYOffset > 2500) {
            setInView(true)
        }
    }, [])

    useEffect(() => {
        document.addEventListener("scroll", handleScroll)

        return () => document.removeEventListener("scroll", handleScroll);
    }, [handleScroll])

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://w3vitals.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://w3vitals.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <Layout metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)} isPromo={true} >
            {/* <Navbar header={props.pageContext.headerMenu} isRelative={true}  lang={props.pageContext.language} /> */}
            <div className="es-bg-m" ref={ref}>
                <header>
                    <nav>
                        <div className="d-flex justify-content-center py-4">
                            {/* <Link to="/">
                                <img id="nav-logo" importance="high" src={logo} className={"w3-logo"} alt="W3Vitals logo" />
                            </Link> */}
                            <Link to="/">
                                <StaticImage
                                    // className="m-auto py-2"
                                    src="../assets/images/logo.png"
                                    alt="W3Vitals logo"
                                    placeholder="none"
                                    loading={"eager"}
                                    quality={90}
                                    formats={["auto", "webp"]}
                                    className="w3-logo"
                                    width={120}
                                    layout="constrained"
                                />
                            </Link>
                        </div>
                    </nav>
                </header>
                <section className="container mx-auto mt-12" style={{ paddingTop: "1rem" }}>
                    <div className="text-center">
                        <h1 className="es-h1">
                            Κατασκευή – Δημιουργία Eshop
                        </h1>
                        <p className="mx-auto pt-4 es-main-p" style={{ maxWidth: "700px", marginBottom: "0" }}>
                            Σε έχει κουράσει το αργό, αναξιόπιστο και unresponsive eshop σου;
                        </p>
                        <p className="mx-auto pb-4 es-main-p" style={{ maxWidth: "700px" }}>
                            Στοχεύοντας στο άριστο, φτιάξαμε το τελευταίο <strong style={{ color: "#fff" }}>eshop</strong> που θα χρειαστείς ποτέ!
                        </p>
                        <div className="flex">
                            <AnchorLink href='#contact-section' className="es-bttn">Επικοινωνία</AnchorLink>
                            <AnchorLink href='#details-section' className="es-bttn-e">Περισσότερα</AnchorLink>
                        </div>
                    </div>
                    <div className="si-conts">
                        <div className="es-rel" style={{ position: "relative" }}>
                            <img src={bgTrans} className="d-none d-md-block" style={{ width: "100%" }} />

                            {/* <picture>
                                <source media="(max-width: 450px)" srcset={eshopIn400} importance="high" />
                                <source media="(min-width: 451px)" srcset={eshopIn} importance="high" />
                                <img src={eshopIn} className="es-in-img" alt="Δημιουργία Eshop" />
                            </picture> */}

                            <StaticImage
                                src="../assets/images/E-shop head image.jpg"
                                alt="Δημιουργία Eshop"
                                placeholder="none"
                                loading={"eager"}
                                formats={["auto", "webp"]}
                                quality={90}
                                className="es-in-img"
                                imgClassName="es-in-img"
                                height={440}
                                layout="constrained"
                            />
                        </div>
                        <div className="es-sl">
                            <img src={shadowLines} style={{ width: "100%" }} />
                        </div>
                    </div>
                </section>

                <section className="container" style={{ position: "relative" }} id="details-section">
                    <div>
                        <h2 className="es-h2">WooCommerce Νέας Γενιάς</h2>
                        <p className="es-main-p mx-auto" style={{ maxWidth: "700px" }}>Θέλεις να αντικαταστήσεις το απαρχαιωμένο σου eshop ή προσπαθείς να δημιουργήσεις ένα ολοκαίνουργιο; Η cloud-based λύση μας έρχεται να σώσει την κατάσταση!</p>
                    </div>
                    <div className="col-12 col-md-6 my-mt d-block d-md-none">
                        <StaticImage
                            // className="m-auto py-2"
                            src="../assets/images/woocommerce.png"
                            alt="Δημιουργία eshop woocommerce"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            loading={"lazy"}
                            quality={100}
                            width={570}
                            layout="constrained"
                        />
                        {/* <img src={codeEditor} /> */}
                    </div>
                    <div className="d-flex align-items-center" style={{ marginTop: "4rem", flexWrap: "wrap" }}>
                        <div className="col-12 col-md-6">
                            <p className="es-alt-p">
                                Το πρόβλημα με τα κοινά eshop είναι οι ξεπερασμένες τεχνολογίες που χρησιμοποιούν και η ελλιπής γνώση υποδομών. Βάζουμε τέλος σ’ αυτό το πρόβλημα, παρέχοντάς σου μια λύση που βασίζεται στο cloud, σε συνδυασμό με ισχυρούς κανόνες προσωρινής μνήμης (cache). Τα οφέλη είναι αναρίθμητα. Τα πιο σημαντικά είναι αυτά:
                            </p>
                        </div>
                        <div className="col-12 col-md-6 my-mt d-none d-md-block">
                            <StaticImage
                                // className="m-auto py-2"
                                src="../assets/images/woocommerce.png"
                                alt="Δημιουργία eshop woocommerce"
                                placeholder="none"
                                formats={["auto", "webp"]}
                                loading={"lazy"}
                                quality={100}
                                width={570}
                                layout="constrained"
                            />
                            {/* <img src={codeEditor} /> */}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between" style={{ marginTop: "2rem", flexWrap: "wrap" }}>
                        <div className="col-12 col-md-4">
                            <img src={perfectCode} width="40" style={{ marginBottom: "1.5rem" }} />
                            <h3 className="es-h3">Μείωση Βounce Rate</h3>
                            <p className="es-alt-p">Οι πελάτες δεν αγωνίζονται να πλοηγηθούν στο eshop σου. Απολαμβάνουν το ταξίδι τους χάρη στην εξαιρετική εμπειρία χρήστη που προσφέρεις.</p>
                        </div>
                        <div className="col-12 my-mt col-md-4">
                            <img src={platformChoise} width="40" style={{ marginBottom: "1.5rem" }} />
                            <h3 className="es-h3">Αυξημένη προβολή στις αναζητήσεις</h3>
                            <p className="es-alt-p">Οι ανιχνευτές της Google ξοδεύουν λιγότερο χρόνο στο eshop σου, που σημαίνει καλύτερο indexability και κορυφαία Google rankings.</p>
                        </div>
                        <div className="col-12 my-mt col-md-4">
                            <img src={uploadCircle} width="40" style={{ marginBottom: "1.5rem" }} />
                            <h3 className="es-h3">Αυξημένες πωλήσεις</h3>
                            <p className="es-alt-p">Ένα eshop υψηλής απόδοσης έχει τεράστιο αντίκτυπο στις πωλήσεις. Βελτίωση ταχύτητας κατά 0.1 s αυξάνει τις μετατροπές κατά 10%.</p>
                        </div>

                    </div>
                </section>
                <section className="container es-core-cont" style={{ marginTop: "4rem" }}>
                    <div className="d-flex align-items-center" style={{ paddingTop: "4rem", flexWrap: "wrap" }}>
                        <div className="col-12 col-md-6 pb-4">
                            {/* <img src={codeEditor} /> */}
                            <StaticImage
                                // className="m-auto py-2"
                                src="../assets/images/tech.png"
                                alt="Δημιουργία eshop woocommerce custom"
                                placeholder="none"
                                loading={"lazy"}
                                quality={100}
                                width={570}
                                layout="constrained"
                            />
                        </div>
                        <div className="col-12 col-md-6 pl-4">
                            <h2 className="es-h2 text-left" style={{ color: "rgb(15, 23, 30)" }}>Ταχύτητα και Κομψότητα</h2>

                            <p className="es-alt-p" style={{ color: "rgb(15, 23, 30)" }}>Τι θα γινόταν αν μπορούσες να συνδυάσεις τεχνολογίες αιχμής με τη σταθερότητα ενός σωστά δομημένου CMS;</p>

                            <p className="es-alt-p" style={{ color: "rgb(15, 23, 30)" }}>Αυτή η σκέψη μας ώθησε να δημιουργήσουμε το δικό μας custom eshop. Τεχνολογίες που δημιούργησαν το Facebook και η Google βρίσκονται τώρα στη διάθεσή σου, δίνοντάς σου τη δυνατότητα να παρέχεις την καλύτερη εμπειρία χρήστη στους πελάτες σου.</p>

                            <p className="es-alt-p" style={{ color: "rgb(15, 23, 30)" }}>
                                Δες την παρακάτω λίστα και βρες τι λείπει απ’ το eshop σου:
                            </p>
                            {/* <p className="es-alt-p" style={{ color: "rgb(15, 23, 30)" }}>
                                Web core vitals, the last update on google's algorithm, is one of the key factors of your indexability rankings
                                therefore your success. Stand out from your competition with the last eshop you'll ever need!!!
                            </p> */}
                        </div>
                    </div>

                    <div className="d-flex justify-content-between es-fk-b" style={{ marginTop: "6rem", flexWrap: "wrap" }}>
                        <div className="col-12 col-md-4 es-cs-p">
                            <img src={modelA} width="40" style={{ marginBottom: "1.5rem" }} />
                            <h3 className="es-h3 es-bl-clr">PWA ready</h3>
                            <p className="es-alt-p es-bl-clr">Βελτιστοποίηση που επιτρέπει τη λήψη του eshop ως εφαρμογή στο κινητό ή τον υπολογιστή. Βασικά της πλεονεκτήματα η δυνατότητα πλοήγησης εκτός σύνδεσης και η υποστήριξη εγγενών ειδοποιήσεων.</p>
                        </div>
                        <div className="col-12 col-md-4 es-cs-p">
                            <img src={modelB} width="40" style={{ marginBottom: "1.5rem" }} />
                            <h3 className="es-h3 es-bl-clr">Adaptability and responsiveness</h3>
                            <p className="es-alt-p es-bl-clr">Χαρακτηριστικά θεμελιώδη για μια εξαιρετική εμπειρία χρήστη. Παρέχουν τη δυνατότητα σε ιστοσελίδες και εφαρμογές να προσαρμόζονται στο μέγεθος της οθόνης της εκάστοτε συσκευής.</p>
                        </div>
                        <div className="col-12 col-md-4 es-cs-p">
                            <img src={modelC} width="40" style={{ marginBottom: "1.5rem" }} />
                            <h3 className="es-h3 es-bl-clr">Data security</h3>
                            <p className="es-alt-p es-bl-clr">Δεν υπάρχει άμεση σύνδεση με τη βάση δεδομένων, τα dependencies, τα δεδομένα του χρήστη ή οποιαδήποτε άλλη ευαίσθητη πληροφορία, κάτι που εγγυάται την ασφάλεια των δεδομένων.</p>
                        </div>
                        <div className="col-12 col-md-4 es-cs-p">
                            <img src={modelD} width="40" style={{ marginBottom: "1.5rem" }} />
                            <h3 className="es-h3 es-bl-clr">Code splitting</h3>
                            <p className="es-alt-p es-bl-clr">Διαχωρισμός του κώδικα του eshop σε διάφορα πακέτα ή στοιχεία που μπορούν στη συνέχεια να φορτωθούν κατά παραγγελία ή παράλληλα, κάτι που ελαχιστοποιεί τον χρόνο φόρτωσης του eshop.</p>
                        </div>
                        <div className="col-12 col-md-4 es-cs-p">
                            <img src={modelE} width="40" style={{ marginBottom: "1.5rem" }} />
                            <h3 className="es-h3 es-bl-clr">Lazy loading</h3>
                            <p className="es-alt-p es-bl-clr">Αντί να φορτώνεται ολόκληρη η σελίδα με το που ανοίγει, όπως συμβαίνει στο bulk loading, το lazy loading επιτρέπει την τμηματική της φόρτωση για μεγαλύτερη ταχύτητα.</p>
                        </div>
                        <div className="col-12 col-md-4 es-cs-p">
                            <img src={modelF} width="40" style={{ marginBottom: "1.5rem" }} />
                            <h3 className="es-h3 es-bl-clr">Served by CDN</h3>
                            <p className="es-alt-p es-bl-clr">Ένα γεωγραφικά κατανεμημένο δίκτυο διακομιστών διανέμει το περιεχόμενο του eshop, εξασφαλίζοντας ίδιο χρόνο απόκρισης ανεξάρτητα απ’ την τοποθεσία του τελικού χρήστη.</p>
                        </div>
                        <div className="col-12 col-md-4 es-cs-p">
                            <img src={modelG} width="40" style={{ marginBottom: "1.5rem" }} />
                            <h3 className="es-h3 es-bl-clr">Auto Scaling</h3>
                            <p className="es-alt-p es-bl-clr">Μέθοδος που παρακολουθεί την κίνηση και την υπολογιστική ισχύ των εφαρμογών και προσαρμόζει αυτόματα την ισχύ για να διατηρείται σταθερή και προβλέψιμη η απόδοση του eshop. </p>
                        </div>
                        <div className="col-12 col-md-4 es-cs-p">
                            <img src={modelH} width="40" style={{ marginBottom: "1.5rem" }} />
                            <h3 className="es-h3 es-bl-clr">Prerendering</h3>
                            <p className="es-alt-p es-bl-clr">Ο server δημιουργεί HTML για κάθε σελίδα εκ των προτέρων. Όταν το ποντίκι αιωρείται πάνω απ’ έναν σύνδεσμο, η σελίδα φορτώνεται και είναι έτοιμη για προβολή.</p>
                        </div>
                        <div className="col-12 col-md-4 es-cs-p">
                            <img src={modelI} width="40" style={{ marginBottom: "1.5rem" }} />
                            <h3 className="es-h3 es-bl-clr">GPU Animations</h3>
                            <p className="es-alt-p es-bl-clr">Διαδραστικά γραφικά 3D και 2D υψηλής απόδοσης σε ένα συμβατό πρόγραμμα περιήγησης ιστού, με την αξιοποίηση της GPU ισχύς της συσκευής. Τέρμα το lagging και οι περιορισμοί!</p>
                        </div>

                    </div>
                    <div>
                        <img src={eTrans} className="es-e-trans" />
                    </div>
                </section>

                <section className="container es-core-cont" style={{ marginTop: "6rem" }}>
                    <div className="d-flex align-items-center" style={{ paddingTop: "4rem", flexWrap: "wrap" }}>
                        <div className="col-12 col-md-6">
                            <h2 className="es-h2 text-left" style={{ color: "rgb(15, 23, 30)" }}>Κατασκευή eshop μέσω ΕΣΠΑ / ΔΥΠΑ <strong style={{fontSize: "1rem"}}>(πρώην ΟΑΕΔ)</strong></h2>

                            <p className="es-alt-p" style={{ color: "rgb(15, 23, 30)" }}>Ενδιαφέρεσαι να αποκτήσεις το δικό σου eshop ή να αναβαθμίσεις το υπάρχον με κρατική επιδότηση μέσω ΕΣΠΑ ή ΔΥΠΑ (πρώην ΟΕΑΔ);</p>

                            <p className="es-alt-p" style={{ color: "rgb(15, 23, 30)" }}>Αναλαμβάνουμε τη δημιουργία eshop, που θα πληροί όλες τις απαραίτητες προϋποθέσεις, για να γίνει αποδεκτό από το εκάστοτε πρόγραμμα, όπως:</p>

                            <ul className="es-alt-p" style={{ color: "rgb(15, 23, 30)" }}>
                               <li>Responsive design για όλες τις οθόνες και συσκευές</li>
                               <li>Online παραγγελιοληψία, πρόγραμμα αποθήκης και δυνατότητα ηλεκτρονικών πληρωμών</li>
                               <li>Πρόσβαση σε άτομα με αναπηρία (ΑμΕΑ) με βάση το πρότυπο WCAG 2</li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-6 pb-4 pl-4" >
                            {/* <img src={codeEditor} /> */}
                            <StaticImage
                                // className="m-auto py-2"
                                src="../assets/images/espa-dypa.png"
                                alt="Κατασκευή eshop μέσω ΕΣΠΑ / ΔΥΠΑ"
                                placeholder="none"
                                loading={"lazy"}
                                quality={100}
                                width={570}
                                layout="constrained"
                            />
                        </div>
                    </div>
                </section>

                <section className="container" style={{ position: "relative", minHeight: "721px" }}>
                    {inView && (
                        <VideoPromo />
                    )}
                </section>
                <section className="es-cs-dvd"></section>
                <section className="es-cs-sect" id="contact-section">
                    {inView && (
                        <ContactPromo
                            key={""}
                            circleShape1={props.data.circleShape1}
                            originator={"Eshop-Promotion"}
                        />
                    )}
                </section>

                <Faqs
                    data={data.page.promoAcf.faqs}
                />

            </div>


            <Footer footer={props.pageContext.footerMenu} extra={data.footerTemplate.footerTemplateAcf} />
        </Layout>
    )
}

export default EshopPromotionalTemaplate

export const pageQuery = graphql`
            query GET_ESHOP_PROMO_PAGE($id: ID! , $footerTemplateUri: ID!) {
                wpgraphql {
                        page(id: $id) {
                        seo {
                        canonical
                            title
                            metaDesc
                            opengraphDescription
                            opengraphTitle
                            opengraphImage {
                                sourceUrl
                            }
                        }

                        promoAcf {
                            faqs{
                                title
                                faqs {
                                    question
                                    answer
                                    visibleOnSite
                                }
                            }
                        }
                    }

                    footerTemplate: template(id: $footerTemplateUri, idType: SLUG) {
                        footerTemplateAcf{
                        addressTitle
                    addressContent
                    content
                }
            }
        }
                    circleShape1: file(relativePath: {eq: "shape/circle-shape1.png" }) {
                        childImageSharp {
                        fixed(width: 350 , height: 386){
                        base64
                    width
                    height
                    src
                    srcSet
                }
            }
        }
    }
`